<template>
  <div style="width: 100%;">
    <div v-show="loading" v-loading="loading" style="height: 500px"></div>
    <div class="set-card" v-for="item in list" v-show="!loading">
      <div class="monitor-text"><i class="el-icon-data-line"></i>{{ item.typeName }}
        <el-button size="mini" type="primary" style="float: right" @click="openAddDialog(item.msId)">添加</el-button>
        <el-button size="mini" type="danger" style="float: right; margin-right: 10px" @click="batchDel">删除勾选</el-button>
      </div>
      <el-table
        @selection-change="handleSelectionChange"
        v-if="item.list.length > 0"
        :data="item.list"
        style="width: 100%; padding: 10px">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="word"
          label="词语"
          align="center">
        </el-table-column>
        <el-table-column
          prop="isEnable"
          label="开启"
          align="center">
          <template scope="{ row }">
            <el-switch
              v-model="row.isEnable"
              @change="changeEnable(row)"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="操作">
          <template scope="{ row }">
            <el-button size="mini" type="danger" @click="delBefore(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-else style="color: lightgray; height: 100px; line-height: 100px; text-align: center; font-size: 13px">
        该主题负面词库暂未添加负面词
      </div>
    </div>
    <el-dialog
      title="添加新词"
      :visible.sync="addDialogFlag"
      width="30%">
      <el-input type="textarea"
                :rows="4" v-model="form.word" placeholder="请输入要添加的词语，批量添加使用中文顿号'、'进行分隔"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button :disalbed="!addLoading" @click="addDialogFlag = false" size="small">取 消</el-button>
        <el-button v-loading="addLoading" :disalbed="!addLoading" type="primary" @click="confirmAdd" size="small">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="delDialogFlag"
      width="30%">
      <span>您确定要删除该词语吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button :disalbed="!delLoading" @click="delDialogFlag = false">取 消</el-button>
        <el-button v-loading="delLoading" :disalbed="!delLoading" type="primary" @click="confirmDel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { fetchAll, addWord, delWord, switchStatus, batchDelWord } from '@/api/negativeThesaurus'
export default {
  name: 'negativeThesaurus',
  data () {
    return ({
      loading: false,
      list: [],
      addDialogFlag: false,
      addLoading: false,
      delDialogFlag: false,
      delLoading: false,
      form: {
        word: '',
        msId: 0
      },
      delId: 0,
      multipleSelection: []
    })
  },
  mounted () {
    eventHub.$emit('msg', '负面词库')
    this.fetch()
  },
  methods: {
    async fetch () {
      this.loading = true
      const res = await fetchAll()
      // res.data[0].list = []
      this.list = res.data
      this.loading = false
    },
    delBefore (row) {
      this.delId = row.id
      this.delDialogFlag = true
    },
    openAddDialog (msId) {
      this.form.word = ''
      this.form.msId = msId
      this.addDialogFlag = true
    },
    async confirmAdd () {
      if (!this.form.word) {
        this.$message.warning('负面词不可为空')
        return
      }
      this.addLoading = true
      const res = await addWord(this.form)
      this.addLoading = false
      this.addDialogFlag = false
      if (res.code === 0) {
        this.fetch()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async confirmDel () {
      this.delLoading = true
      const res = await delWord({ id: this.delId })
      this.delLoading = false
      this.delDialogFlag = false
      this.delId = 0
      if (res.code === 0) {
        this.fetch()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    async changeEnable (row) {
      const res = await switchStatus(row)
      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success(res.msg)
      }
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(val)
    },
    async batchDel () {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('没有勾选任何负面词')
        return
      }
      var idArray = []
      for (var i = 0; i < this.multipleSelection.length; i++) {
        idArray.push(this.multipleSelection[i].id)
      }
      const res = await batchDelWord({ idArray: idArray })
      if (res.code !== 0) {
        this.$message.error(res.msg)
      } else {
        this.$message.success(res.msg)
        this.fetch()
      }
    }
  }
}
</script>

<style scoped>
  .set-card {
    width: 100%;
    border-radius: 5px;
    /*box-shadow: 0px 0px 10px 0px lightgray;*/
    background-color: #FFFFFF;
    margin-bottom: 20px;
  }
  .set-card .monitor-text {
    padding: 10px;
    border-bottom: 1px solid #F2F2F2;
    color: #3372C7;
    font-size: 15px;
  }
  .set-card .monitor-text i {
    font-size: 20px;
    position: relative;
    top: 2px;
    padding-right: 5px;
  }
</style>
