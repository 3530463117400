import request from '@/utils/request'

export function fetchAll () {
  return request({
    url: '/negativeThesaurus/fetch',
    method: 'POST'
  })
}
export function addWord (data) {
  return request({
    url: '/negativeThesaurus/add',
    method: 'POST',
    data
  })
}
export function delWord (data) {
  return request({
    url: '/negativeThesaurus/del',
    method: 'POST',
    data
  })
}
export function switchStatus (data) {
  return request({
    url: '/negativeThesaurus/switch',
    method: 'POST',
    data
  })
}
export function batchDelWord (data) {
  return request({
    url: '/negativeThesaurus/batchDel',
    method: 'POST',
    data
  })
}
